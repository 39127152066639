import { BUILD_ID } from "../../analytics";
import { Override, TimeZone } from "../../types";
import { dateToStr, getDurationString, MILLISECONDS_PER_HOUR, MILLISECONDS_PER_MINUTE } from "../../utils/dates";
import { RequestParams, TimePolicy as TimePolicyDto, ZoneId } from "../client";
import { EasyTypedErrorPromise } from "../domainTypeHelpers";
import { CalendarEvent, calendarEventToDto, dtoToCalendarEvent } from "../Events";
import { nullable, TransformDomain, YYYYMMDD } from "../types";
import { dtoToThinPerson, dtoToTimePolicy, ThinPerson, thinPersonToDto, TimePolicy, timePolicyToDto } from "../Users";
import {
  ConferenceType,
  CreateSchedulingLinkGroupRequest as CreateSchedulingLinkGroupRequestDto,
  CreateSchedulingLinkRequest as CreateSchedulingLinkRequestDto,
  DelayStart as DelayStartDto,
  IconType as SchedulingLinkIconTypeDto,
  MembershipRequest as MembershipRequestDto,
  PartialSchedulingLinkMeeting as PartialSchedulingLinkMeetingDto,
  ReclaimApi,
  SchedulingLink as SchedulingLinkDto,
  SchedulingLinkGroup as SchedulingLinkGroupDto,
  SchedulingLinkGroupPatchRequest as SchedulingLinkGroupPatchRequestDto,
  SchedulingLinkMeetingAvailability as SchedulingLinkMeetingAvailabilityDto,
  SchedulingLinkMeetingAvailabilitySlot as SchedulingLinkMeetingAvailabilitySlotDto,
  SchedulingLinkMeetingCancellation,
  SchedulingLinkMeetingEvent as SchedulingLinkMeetingEventDto,
  SchedulingLinkMeetingRequest as SchedulingLinkMeetingRequestDto,
  SchedulingLinkMember as SchedulingLinkMemberDto,
  SchedulingLinkMemberStatus as SchedulingLinkMemberStatusDto,
  SchedulingLinkOrganizerView as SchedulingLinkOrganizerViewDto,
  SchedulingLinkRole as SchedulingLinkRoleDto,
  SchedulingPriority as SchedulingLinkPriorityDto,
  TimePolicyType as TimePolicyTypeDto,
  UserSlug as UserSlugDto,
} from "./scheduling-links-client";
import { PatchMembershipRequestDto, PatchSchedulingLinkRequestDto } from "./SchedulingLinks.dtoAdditions";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

export type SchedulingLinkIconType = `${SchedulingLinkIconTypeDto}`;
export type SchedulingLinkPriority = `${SchedulingLinkPriorityDto}`;
export type TimePolicyType = `${TimePolicyTypeDto}`;
export type ConferenceTypeStr = `${ConferenceType}`;
export type DelayStart = `${DelayStartDto}`;

export const DELAY_STARTS = Object.values(DelayStartDto);

/**
 * SchedulingLink
 */

export type SchedulingLink = Readonly<
  Override<
    Omit<SchedulingLinkDto, "coOrganizers" | "organizer">,
    {
      id: string;
      startDate: Date | undefined;
      endDate: Date | undefined;
      timePolicyType: TimePolicyType;
      oneOffPolicy?: TimePolicy | null;
      iconType: SchedulingLinkIconType;
      conferenceType?: ConferenceTypeStr | null;
      delayStart: DelayStart;
      slug: string;
      description?: string;
      daysIntoFuture?: number;
      delayStartUnits?: number;
      location?: string;
      organizerRefCode?: string;
      effectiveTimePolicy?: TimePolicy;
      organizerView: SchedulingLinkOrganizerView;
      organizers: readonly SchedulingLinkOrganizerView[];
      mainOrganizerId: string;
      linkGroupId?: string;
      linkGroupName?: string;
      pageSlug: string;
      defaultDuration?: number;
      attendeeName?: string;
      attendeeEmail?: string;
    }
  >
>;

export type CreateSchedulingLinkRequest = Override<
  CreateSchedulingLinkRequestDto,
  {
    startDate?: Date;
    endDate?: Date;
    timePolicyType: TimePolicyType;
    oneOffPolicy?: TimePolicy;
    iconType: SchedulingLinkIconType;
    priority: SchedulingLinkPriority;
    conferenceType?: ConferenceTypeStr;
    delayStart: DelayStart;
    slug: string;
    members?: readonly CreateMembershipRequest[];
    attendeeName?: string;
    attendeeEmail?: string;
  }
>;

export type PatchSchedulingLinkRequest = Readonly<
  Override<
    PatchSchedulingLinkRequestDto,
    {
      timePolicyType?: TimePolicyType;
      oneOffPolicy?: TimePolicy | null;
      priority?: SchedulingLinkPriority | null;
      iconType?: SchedulingLinkIconType;
      conferenceType?: ConferenceTypeStr | null;
      startDate?: Date | null;
      endDate?: Date | null;
      delayStart: DelayStart;
      effectiveTimePolicy?: TimePolicy;
      members?: readonly PatchMembershipRequest[] | null;
      organizers?: readonly SchedulingLinkOrganizerView[];
      defaultDuration?: number | null;
    }
  >
>;

export type MembershipRequest = Readonly<
  Override<
    MembershipRequestDto,
    {
      timePolicyType: TimePolicyType;
      oneOffPolicy?: TimePolicy;
    }
  >
>;

export type PatchMembershipRequest = Readonly<
  Override<
    PatchMembershipRequestDto,
    {
      timePolicyType?: TimePolicyType;
      oneOffPolicy?: TimePolicy | null;
    }
  >
>;

export type CreateMembershipRequest = Readonly<
  Override<
    MembershipRequestDto,
    {
      timePolicyType: TimePolicyType;
      oneOffPolicy?: TimePolicy;
    }
  >
>;

export type SchedulingLinkMemberStatus = `${SchedulingLinkMemberStatusDto}`;

export type SchedulingLinkMember = Readonly<
  Override<
    SchedulingLinkMemberDto,
    {
      member: ThinPerson;
      timePolicyType: TimePolicyType;
      oneOffPolicy?: TimePolicy;
      status?: SchedulingLinkMemberStatus;
    }
  >
>;

export type UserSlug = Readonly<
  Override<
    UserSlugDto,
    {
      slug: string;
    }
  >
>;

export type SchedulingLinkRole = `${SchedulingLinkRoleDto}`;

export type SchedulingLinkOrganizerView = Readonly<
  Override<
    SchedulingLinkOrganizerViewDto,
    {
      organizer: ThinPerson;
      role: SchedulingLinkRole;
      timezone: TimeZone;
      timePolicyType: TimePolicyType;
      oneOffPolicy?: TimePolicy;
      resolvedTimePolicy: TimePolicy;
      status?: SchedulingLinkMemberStatus;
    }
  >
>;

// This IS just a simple cast,  but casting is
// dangerous in type-land so offloading that
// responsibility to a single entity is useful.
// This way we don't have to track a million
// hidden type errors if something breaks.
export const timePolicyTypeToDto = (timePolicyType: TimePolicyType): TimePolicyTypeDto =>
  timePolicyType as TimePolicyTypeDto;

// ZoneIds are incorrectly identified by swagger, they're just strings
export const zoneIdToTimeZone = (zoneId: ZoneId): TimeZone => zoneId as unknown as TimeZone;
export const timeZoneToZoneId = (timeZone: TimeZone): ZoneId => timeZone as unknown as ZoneId;

export const dtoToSchedulingLinkMemberStatus = (dto: SchedulingLinkMemberStatusDto): SchedulingLinkMemberStatus =>
  dto as SchedulingLinkMemberStatus;
export const schedulingLinkMemberStatusToDto = (status: SchedulingLinkMemberStatus): SchedulingLinkMemberStatusDto =>
  status as SchedulingLinkMemberStatusDto;

export const schedulingLinkRoleToDto = (role: SchedulingLinkRole): SchedulingLinkRoleDto =>
  role as SchedulingLinkRoleDto;

export const dtoToSchedulingLinkOrganizerView = (dto: SchedulingLinkOrganizerViewDto): SchedulingLinkOrganizerView => ({
  ...dto,
  organizer: dtoToThinPerson(dto.organizer),
  timezone: zoneIdToTimeZone(dto.timezone),
  oneOffPolicy: dto.oneOffPolicy ? dtoToTimePolicy(dto.oneOffPolicy) : undefined,
  resolvedTimePolicy: dto.resolvedTimePolicy ? dtoToTimePolicy(dto.resolvedTimePolicy) : { dayHours: {} },
  status: dto.status ? dtoToSchedulingLinkMemberStatus(dto.status) : undefined,
});

export const schedulingLinkOrganizerViewToDto = (org: SchedulingLinkOrganizerView): SchedulingLinkOrganizerViewDto => ({
  ...org,
  organizer: thinPersonToDto(org.organizer),
  timezone: timeZoneToZoneId(org.timezone),
  oneOffPolicy: org.oneOffPolicy && timePolicyToDto(org.oneOffPolicy),
  resolvedTimePolicy: timePolicyToDto(org.resolvedTimePolicy),
  status: org.status && schedulingLinkMemberStatusToDto(org.status),
  role: schedulingLinkRoleToDto(org.role),
  timePolicyType: timePolicyTypeToDto(org.timePolicyType),
});

export const dtoToSchedulingLinkMember = (dto: SchedulingLinkMemberDto): SchedulingLinkMember => ({
  ...dto,
  oneOffPolicy: dto.oneOffPolicy ? dtoToTimePolicy(dto.oneOffPolicy) : undefined,
  status: dto.status || undefined,
});

export const schedulingLinkMemberToDto = (member: SchedulingLinkMember): SchedulingLinkMember => ({
  ...member,
});

export const dtoToUserSlug = (dto: UserSlugDto): UserSlug => ({ ...dto });

export const dtoToSchedulingLink = (dto: SchedulingLinkDto): SchedulingLink => ({
  ...dto,
  startDate: dto.startDate ? new Date(dto.startDate) : undefined,
  endDate: dto.endDate ? new Date(dto.endDate) : undefined,
  timePolicyType: dto.timePolicyType as TimePolicyType,
  oneOffPolicy: dto.oneOffPolicy as TimePolicy,
  effectiveTimePolicy: dto.effectiveTimePolicy as TimePolicy,

  // null -> undefined conversions
  description: dto.description || undefined,
  daysIntoFuture: dto.daysIntoFuture || undefined,
  location: dto.location || undefined,
  organizerRefCode: dto.organizerRefCode || undefined,
  delayStartUnits: dto.delayStartUnits || undefined,
  defaultDuration: dto.defaultDuration || undefined,

  organizerView: dtoToSchedulingLinkOrganizerView(
    dto.organizers.find(({ role }) => role === SchedulingLinkRoleDto.MAINORGANIZER) as SchedulingLinkOrganizerViewDto
  ),
  organizers: dto.organizers.map(dtoToSchedulingLinkOrganizerView),
  linkGroupId: dto.linkGroupId || undefined,
  linkGroupName: dto.linkGroupName || undefined,
  attendeeName: dto.attendeeName || undefined,
  attendeeEmail: dto.attendeeEmail || undefined,
});

export const membershipRequestToDto = (data: MembershipRequest): MembershipRequestDto => ({
  ...data,
  timePolicyType: timePolicyTypeToDto(data.timePolicyType),
  oneOffPolicy: data.oneOffPolicy && timePolicyToDto(data.oneOffPolicy),
});

export const createMembershipRequestToDto = (data: CreateMembershipRequest): MembershipRequestDto => ({
  ...data,
  timePolicyType: timePolicyTypeToDto(data.timePolicyType),
  oneOffPolicy: data.oneOffPolicy && timePolicyToDto(data.oneOffPolicy),
});

export const patchMembershipRequestToDto = (data: PatchMembershipRequest): PatchMembershipRequestDto => ({
  ...data,
  // Currently timePolicyType is required, although I
  // believe for patches it should be able to be undefined.
  timePolicyType: (data.timePolicyType && timePolicyTypeToDto(data.timePolicyType)) as TimePolicyTypeDto,
  oneOffPolicy: data.oneOffPolicy && timePolicyToDto(data.oneOffPolicy),
});

export const createSchedulingLinkRequestToDto = (
  link: CreateSchedulingLinkRequest
): CreateSchedulingLinkRequestDto => ({
  ...link,
  timePolicyType: link.timePolicyType as TimePolicyTypeDto,
  startDate: nullable(link.startDate, dateToStr),
  endDate: nullable(link.endDate, dateToStr),
  priority: link.priority as SchedulingLinkPriorityDto,
  iconType: link.iconType as SchedulingLinkIconTypeDto,
  oneOffPolicy: link.oneOffPolicy as TimePolicyDto,
  conferenceType: link.conferenceType as ConferenceType,
  delayStart: link.delayStart as DelayStartDto,
  members: link.members?.map(createMembershipRequestToDto),
});

export const patchSchedulingLinkRequestToDto = (link: PatchSchedulingLinkRequest): PatchSchedulingLinkRequestDto => ({
  ...link,
  timePolicyType: link.timePolicyType as TimePolicyTypeDto,
  effectiveTimePolicy: link.effectiveTimePolicy as TimePolicyDto,
  startDate: nullable(link.startDate, dateToStr),
  endDate: nullable(link.endDate, dateToStr),
  priority: link.priority as unknown as SchedulingLinkPriorityDto,
  iconType: link.iconType as SchedulingLinkIconTypeDto,
  oneOffPolicy: link.oneOffPolicy && timePolicyToDto(link.oneOffPolicy),
  delayStart: link.delayStart as DelayStartDto,
  conferenceType: (link.conferenceType as ConferenceType) || null,
  members: link.members?.map(patchMembershipRequestToDto),
  organizers: link.organizers?.map(schedulingLinkOrganizerViewToDto),
});

export const userSlugtoDto = (dto: UserSlugDto): UserSlug => ({ ...dto });

/**
 * SchedulingLinkMeetingAvailabilitySlot
 */

export type SchedulingLinkMeetingAvailabilitySlot = Readonly<
  Override<
    Omit<SchedulingLinkMeetingAvailabilitySlotDto, "suggested">,
    {
      startTime: Date;
      endTime: Date;
    }
  >
>;

export const dtoToSchedulingLinkMeetingAvailabilitySlot = (
  dto: SchedulingLinkMeetingAvailabilitySlotDto
): SchedulingLinkMeetingAvailabilitySlot => ({
  ...dto,
  startTime: new Date(dto.startTime),
  endTime: new Date(dto.endTime),
});

export const schedulingLinkMeetingAvailabilitySlotToDto = (
  data: SchedulingLinkMeetingAvailabilitySlot
): SchedulingLinkMeetingAvailabilitySlotDto => ({
  ...data,
  startTime: data.startTime.toISOString(),
  endTime: data.endTime.toISOString(),
});

/**
 * SchedulingLinkMeetingAvailability
 */

export type SchedulingLinkMeetingAvailabilityTimes = Record<number, SchedulingLinkMeetingAvailabilitySlot[]>;

export type SchedulingLinkMeetingAvailability = Readonly<
  Override<
    SchedulingLinkMeetingAvailabilityDto,
    {
      inviteeEvents?: CalendarEvent[];
      availableTimes: SchedulingLinkMeetingAvailabilityTimes;
    }
  >
>;

export const dtoToSchedulingLinkMeetingAvailability = (
  dto: SchedulingLinkMeetingAvailabilityDto
): SchedulingLinkMeetingAvailability => ({
  ...dto,
  inviteeEvents: dto.inviteeEvents?.map(dtoToCalendarEvent),
  availableTimes: Object.entries(dto.availableTimes).reduce((map, [key, slots]) => {
    map[key] = slots.map(dtoToSchedulingLinkMeetingAvailabilitySlot);
    return map;
  }, {} as Record<string, SchedulingLinkMeetingAvailabilitySlot[]>),
});

export const schedulingLinkMeetingAvailabilityToDto = (
  data: SchedulingLinkMeetingAvailability
): SchedulingLinkMeetingAvailabilityDto => ({
  ...data,
  inviteeEvents: data.inviteeEvents?.map(calendarEventToDto),
  availableTimes: Object.entries(data.availableTimes).reduce((map, [key, slots]) => {
    map[key] = slots.map(schedulingLinkMeetingAvailabilitySlotToDto);
    return map;
  }, {} as Record<string, SchedulingLinkMeetingAvailabilitySlotDto[]>),
});

/**
 * SchedulingLinkMeetingRequest
 */

export type SchedulingLinkMeetingRequest = Readonly<
  Override<
    SchedulingLinkMeetingRequestDto,
    {
      start: Date;
      end: Date;
    }
  >
>;

export const dtoToSchedulingLinkMeetingRequest = (
  dto: SchedulingLinkMeetingRequestDto
): SchedulingLinkMeetingRequest => ({
  ...dto,
  start: new Date(dto.start),
  end: new Date(dto.end),
});

export const schedulingLinkMeetingRequestToDto = (
  data: SchedulingLinkMeetingRequest
): SchedulingLinkMeetingRequestDto => ({
  ...data,
  start: data.start.toISOString(),
  end: data.end.toISOString(),
});

/**
 * SchedulingLinkMeetingEvent
 */

export type SchedulingLinkMeetingEvent<HAS_EXTRAS extends boolean = boolean> = Readonly<
  Omit<
    Override<
      SchedulingLinkMeetingEventDto,
      {
        event: CalendarEvent;
        attendee: ThinPerson;
      }
    >,
    "schedulingLink" | "userSlug"
  > &
    (HAS_EXTRAS extends true
      ? {
          schedulingLink?: SchedulingLink | null;
          userSlug?: UserSlug | null;
        }
      : {})
>;

export const dtoToSchedulingLinkMeetingEvent = <HAS_EXTRAS extends boolean>(
  dto: SchedulingLinkMeetingEventDto
): SchedulingLinkMeetingEvent<HAS_EXTRAS> => {
  if (typeof dto.meetingId !== "string") throw new Error("SchedulingLinkMeetingEvent must have a meetingId");

  const sl: SchedulingLinkMeetingEvent<true> = {
    ...dto,
    event: dtoToCalendarEvent(dto.event),
    schedulingLink: dto.schedulingLink && dtoToSchedulingLink(dto.schedulingLink),
    userSlug: dto.userSlug && dtoToUserSlug(dto.userSlug),
  };

  return sl as SchedulingLinkMeetingEvent<HAS_EXTRAS>;
};

/**
 * PartialSchedulingLinkMeeting
 */

export type PartialSchedulingLinkMeeting = Readonly<
  Override<
    PartialSchedulingLinkMeetingDto,
    {
      start?: Date | null;
      end?: Date | null;
    }
  >
>;

export const partialSchedulingLinkMeetingToDto = (
  data: PartialSchedulingLinkMeeting
): PartialSchedulingLinkMeetingDto => ({
  ...data,
  start: data.start?.toISOString(),
  end: data.end?.toISOString(),
});

/**
 * SchedulingLinkGroup
 */

export type SchedulingLinkGroup = Override<SchedulingLinkGroupDto, {}>;
export type CreateSchedulingLinkGroupRequest = Override<CreateSchedulingLinkGroupRequestDto, {}>;
export type PatchSchedulingLinkGroupRequest = Override<SchedulingLinkGroupPatchRequestDto, {}>;

export const dtoToSchedulingLinkGroup = (dto: SchedulingLinkGroupDto): SchedulingLinkGroup => ({ ...dto });
export const schedulingLinkGroupToDto = (data: SchedulingLinkGroup): SchedulingLinkGroupDto => ({ ...data });

export const dtoToCreateSchedulingLinkGroupRequest = (
  dto: CreateSchedulingLinkGroupRequestDto
): CreateSchedulingLinkGroupRequest => ({ ...dto });
export const createSchedulingLinkGroupRequestToDto = (
  data: CreateSchedulingLinkGroupRequest
): CreateSchedulingLinkGroupRequestDto => ({ ...data });

export const dtoToPatchSchedulingLinkGroupRequest = (
  dto: SchedulingLinkGroupPatchRequestDto
): PatchSchedulingLinkGroupRequest => ({ ...dto });
export const patchSchedulingLinkGroupRequestToDto = (
  data: PatchSchedulingLinkGroupRequest
): SchedulingLinkGroupPatchRequestDto => ({ ...data });

export class SchedulingLinksDomain extends TransformDomain<SchedulingLink, SchedulingLinkDto> {
  /**
   * This domain currently has its own separate client generation. Use
   * the domainApi instead of api for executing module requests.
   */
  domainApi: ReclaimApi;

  constructor(...args) {
    super(...args);

    this.domainApi = new ReclaimApi({ baseUrl: API_BASE_URI, BUILD_ID });
  }

  resource = "SchedulingLink";
  cacheKey = "scheduling_links";
  pk = "id";

  public deserialize = dtoToSchedulingLink;

  list = this.deserializeResponse((excludeDisabled?: boolean) =>
    // invert includeDisabled so it can syntactically default to false
    this.domainApi.schedulingLink.getAllLinks({ includeDisabled: !excludeDisabled })
  );

  get = this.deserializeResponse((id: string) => this.domainApi.schedulingLink.getLink(id));

  getAllLinksForUserSlug = this.deserializeResponse((userSlug: string, excludeDisabled?: boolean) =>
    this.domainApi.schedulingLink.getAllLinksForUserSlug(userSlug, { includeDisabled: !excludeDisabled })
  );

  create = this.deserializeResponse((payload: CreateSchedulingLinkRequest) =>
    this.domainApi.schedulingLink.createLink(createSchedulingLinkRequestToDto(payload))
  );

  patch = this.deserializeResponse((id: string, payload: PatchSchedulingLinkRequest) =>
    this.domainApi.schedulingLink.updateLink(id, patchSchedulingLinkRequestToDto(payload))
  );

  getMeetingSlots = async (
    schedulingLinkId: string,
    date: YYYYMMDD,
    tz: TimeZone
  ): Promise<SchedulingLinkMeetingAvailability> => {
    return dtoToSchedulingLinkMeetingAvailability(
      await this.domainApi.schedulingLink.getMeetingSlots1(schedulingLinkId, {
        date,
        zoneId: tz,
      })
    );
  };

  delete = (id: string) => this.domainApi.schedulingLink.deleteLink(id);

  getUnavailableDates = (linkId: string, startDate: YYYYMMDD, endDate: YYYYMMDD, tz: string, params?: RequestParams) =>
    this.domainApi.schedulingLink.getAvailabilityForDates1(linkId, { startDate, endDate, zoneId: tz }, params);

  requestMeeting = async (
    schedulingLInkId: string,
    request: SchedulingLinkMeetingRequest
  ): Promise<SchedulingLinkMeetingEvent<true>> =>
    dtoToSchedulingLinkMeetingEvent<true>(
      (await this.domainApi.schedulingLink.createMeeting(
        schedulingLInkId,
        schedulingLinkMeetingRequestToDto(request)
      )) as SchedulingLinkMeetingEventDto
    );

  getMeeting = async (meetingId: string) =>
    dtoToSchedulingLinkMeetingEvent<true>(
      (await this.domainApi.schedulingLink.findMeeting(meetingId)) as SchedulingLinkMeetingEventDto
    );

  cancelMeeting = async (meetingId: string) => this.domainApi.schedulingLink.deleteMeeting(meetingId);

  cancelMeetingWithMessage = async (meetingId: string, cancelMessage: SchedulingLinkMeetingCancellation) =>
    this.domainApi.schedulingLink.deleteMeetingWithMessage(meetingId, cancelMessage);

  updateMeeting = async (meetingId: string, patch: PartialSchedulingLinkMeeting) =>
    this.domainApi.schedulingLink.updateMeeting(meetingId, partialSchedulingLinkMeetingToDto(patch));

  /**
   * Gets user slug
   * @param userInputSlug
   * @returns a promise which resolves to a `UserSlug`
   * @deprecated not *really* deprecated, **BUT DO NOT USE THIS** - use `useUserSlug` instead.
   */
  getMyUserSlug = async () => dtoToUserSlug(await this.domainApi.schedulingLink.getMyUserSlug());

  userSlugExists = (userInputSlug: string) =>
    this.domainApi.schedulingLink.userSlugExists({ slug: userInputSlug }) as unknown as EasyTypedErrorPromise<boolean>;

  async getLinkForSlugs(userSlug: string, linkSlug: string, skipTransform?: false): Promise<SchedulingLink>;
  async getLinkForSlugs(userSlug: string, linkSlug: string, skipTransform: true): Promise<SchedulingLinkDto>;
  async getLinkForSlugs(
    userSlug: string,
    linkSlug: string,
    skipTransform = false
  ): Promise<SchedulingLink | SchedulingLinkDto> {
    const dto = await this.domainApi.schedulingLink.getLinkForUserAndLinkSlug(userSlug, linkSlug);
    return skipTransform ? dto : dtoToSchedulingLink(dto);
  }

  /**
   * Sets the user slug
   * @param payload
   * @returns
   * @deprecated not *really* deprecated, **BUT DO NOT USE THIS** - use `useUserSlug` instead.
   */
  updateMyUserSlug = (payload: UserSlug) => this.domainApi.schedulingLink.updateMyUserSlug(userSlugtoDto(payload));

  schedulingLinkSlugExists = (slug: string) =>
    this.domainApi.schedulingLink.schedulingLinkSlugExists({ slug }) as unknown as EasyTypedErrorPromise<boolean>;

  listMeetingsForSchedulingLink = async (schedulingLinkId: string) =>
    (await this.domainApi.schedulingLink.listMeetingsForSchedulingLink(schedulingLinkId)).map((meeting) =>
      dtoToSchedulingLinkMeetingEvent<false>(meeting)
    );

  getSchedulingLinkGroups = () => this.domainApi.schedulingLink.getAllLinkGroupsForUser();

  listGroups = async () =>
    (await this.domainApi.schedulingLink.getAllLinkGroupsForUser()).map(dtoToSchedulingLinkGroup);

  createGroup = (group: CreateSchedulingLinkGroupRequest) =>
    this.domainApi.schedulingLink.createSchedulingLinkGroup(createSchedulingLinkGroupRequestToDto(group));

  updateGroup = (groupId: string, group: PatchSchedulingLinkGroupRequest) =>
    this.domainApi.schedulingLink.updateschedulingLinkGroup(groupId, patchSchedulingLinkGroupRequestToDto(group));

  deleteSchedulingLinkGroupAndLinks = (id: string) => this.domainApi.schedulingLink.deleteLinkGroup(id);

  deleteSchedulingLinkGroupAndMoveLinks = (id: string, moveLinksToGroupId: string | null) =>
    this.domainApi.schedulingLink.deleteLinkGroupAndMoveLinks(id, { moveLinksToGroupId });

  getEffectiveTimePolicy = (organizer: MembershipRequest, coOrganizers: readonly MembershipRequest[]) => {
    return this.domainApi.schedulingLink.effectiveTimePolicy({
      coOrganizers: coOrganizers.map(membershipRequestToDto),
      organizer: membershipRequestToDto(organizer),
    });
  };
}

export const getSchedulingLinkDurationStr = (link: SchedulingLink): string => {
  if (!link.durations.length) return "No duration set";

  link.durations.sort((a, b) => a - b);

  if (link.durations.length === 1) {
    return getDurationString(link.durations[0] * MILLISECONDS_PER_MINUTE);
  } else if (link.durations.length === 2) {
    return `${getDurationString(link.durations[0] * MILLISECONDS_PER_MINUTE)}, ${getDurationString(
      link.durations[1] * MILLISECONDS_PER_MINUTE
    )}`;
  } else {
    return `${getDurationString(link.durations[0] * MILLISECONDS_PER_MINUTE)}, ${getDurationString(
      link.durations[1] * MILLISECONDS_PER_MINUTE
    )}, ${getDurationString(link.durations[2] * MILLISECONDS_PER_MINUTE)}`;
  }
};

export const getSchedulingLinkDurationStrLessUnits = (link: SchedulingLink): string => {
  if (!link.durations.length) return "No duration set";

  link.durations.sort((a, b) => a - b);

  // if all durations are <1hr - return only last one with units (mins)
  // or if all durations are divisable by one hour - only return last one with units (hrs)
  if (
    link.durations.every((duration) => duration * MILLISECONDS_PER_MINUTE < MILLISECONDS_PER_HOUR) ||
    link.durations.every((duration) => (duration * MILLISECONDS_PER_MINUTE) % MILLISECONDS_PER_HOUR === 0)
  ) {
    // map through array
    // only return the last duration string WITHOUT the noUnit:true option
    return link.durations
      .map((duration, index, { length }) =>
        getDurationString(duration * MILLISECONDS_PER_MINUTE, { noUnits: index + 1 !== length })
      )
      .join(", ");
  } else {
    // else return all with units
    return link.durations.map((duration) => getDurationString(duration * MILLISECONDS_PER_MINUTE)).join(", ");
  }
};
